<template>
  <el-tabs class="store-cate-type-tabs-component" v-on="$listeners" v-bind="$attrs">
    <el-tab-pane :label="label" :name="id" :key="id" v-for="{ label, id, slotName } in list">
      <slot v-if="isShowTab(id)" :name="slotName"></slot>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      require: true
    }
  },

  data() {
    return {
      tabCache: []
    }
  },

  watch: {
    list() {
      //初始化缓存tabs
      this.initTabCache()
    },

    //缓存
    '$attrs.value'(newVal) {
      this.tabCache = [...new Set([...this.tabCache, newVal])]
    }
  },

  computed: {
    isShowTab() {
      return (id) => {
        return this.tabCache.includes(id)
      }
    }
  },

  methods: {
    //初始化缓存tabs
    initTabCache() {
      if (!this.list.length) return
      const [{ id }] = this.list
      this.tabCache.push(id)
    }
  }
}
</script>

<style lang="scss" scoped>
.store-cate-type-tabs-component {
  .el-tabs__item {
    font-size: 14px;
    font-weight: bold;
    color: #495060;
  }
  ::v-deep {
    .el-tabs__nav-wrap::after {
      height: 1px;
      background-color: #f0f4f8;
    }
  }
}
</style>
